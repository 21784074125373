import React, { useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import './styles/loading.css';
import Login from './components/Login';
import { Context } from "./context/UserContext";
const Header = React.lazy( () => import('./components/Ui/Header') );
const Body = React.lazy( () => import('./components/Ui/Body') );
const Footer = React.lazy( () => import('./components/Ui/Footer') );
const Dashboard = React.lazy( () => import('./components/Dashboard') );
const Study = React.lazy( () => import('./components/Study') );
const Bonus = React.lazy( () => import('./components/Bonus') );
const Feedback = React.lazy( () => import('./components/Feedback') );
const Lesson = React.lazy( () => import('./components/Lesson') );
const CourseManager = React.lazy( () => import('./components/CourseManager') );
const Page404 = React.lazy( () => import('./components/Ui/404'))


function App() {

  const [ credentials, setCredentials ] = useState({ 
    role: '',
    username: '',
    auth: false
  });

  function ToAuth() {
    return (
      <Routes>
        <Route path="/" Component={Login}/>
        <Route path="*" Component={Page404}/>
      </Routes>
    )
  }

  function Authenticated() {
    return (
      <>
        <Header/>
          <Body>
            <Routes>
              <Route path="/" Component={Dashboard}/>
              <Route path="/premia" Component={Bonus}/>
              <Route path="/szkolenie" Component={Study}/>
              <Route path="/feedback" Component={Feedback}/>
              <Route path="/szkolenie/kurs/:id" Component={Lesson}/>
              <Route path="/course-manager" Component={CourseManager}/>
              <Route path="*" Component={Page404}/>
            </Routes>
          </Body>
        <Footer/>
      </>
    )
  };
  
  return (
    <Context.Provider value={{ credentials, setCredentials }}>
      <Suspense fallback={<div className="loadingScreen"><h1>loading...</h1></div>}>
        { !credentials.auth ? <ToAuth/> : <Authenticated/> }
      </Suspense>
    </Context.Provider>
  )
}

export default App;