import { useContext, useState } from 'react';
import { Context } from '../context/UserContext';
import '../styles/login.css';
import logo from '../assets/ax_logo.png';

const Login = () => {
    // Context
    const value = useContext(Context);
    const [ loginError, setLoginError ] = useState(false);
    
    const handleLogin = async event => {
        event.preventDefault();
        try {
            await fetch(process.env.REACT_APP_BACKEND_URL + '/login', {
                method: 'POST',
                body: JSON.stringify({
                    login: event.target[0].value,
                    password: event.target[1].value,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then( response => response.json() )
            .then( response => {
                if ( response !== 'access denied' || null ) {
                    value.setCredentials({
                        role: response.role,
                        username: response.username,
                        auth: true,
                    })
                } else {
                    setLoginError(true)
                }
                return;
            });
        } catch (error) { console.log(error) }
    };
    return (
        <div className='login'>
            <div className='left'>
                <img src={logo} alt="logo"/>
                <form onSubmit={handleLogin}>
                    <label>
                        <p>login:</p>
                        <input type="text"/>
                    </label>
                    <label>
                        <p>hasło:</p>
                        <input type="password"/>
                    </label>
                    <label>
                        <input type="submit" value="Zaloguj się"/>
                    </label>
                </form>
                <div className='errorArea'>
                    { loginError.visible && <h6>Złe dane logowania.</h6> }
                </div>
            </div>
            <div className='right'/>
        </div>
    )
}

export default Login;